<template>
  <Layout>
    <div class="app-share-edit">
      <a-page-header
        title="分享有礼"
        sub-title="分享者和助力者都可以领取优惠券"
      />

      <div class="adv-form">
        <a-form-model :model="form" ref="form" v-bind="horizonLayout">
          <a-form-model-item
            required
            label="活动名称"
            prop="title"
            :rules="[
              {
                required: true,
                message: '活动名称必填'
              }
            ]"
          >
            <a-input v-model="form.title" />
          </a-form-model-item>
          <a-form-model-item
            required
            label="开始时间"
            prop="startTime"
            :rules="{
              required: true,
              message: '开始时间必填'
            }"
          >
            <a-date-picker
              v-model="form.startTime"
              valueFormat="YYYY-MM-DD HH:mm"
              format="YYYY-MM-DD HH:mm"
              :disabled-date="disabledStartDate"
              :show-time="{ format: 'h:mm' }"
            />
          </a-form-model-item>

          <a-form-model-item
            required
            label="结束时间"
            prop="endTime"
            :rules="{
              required: true,
              message: '结束时间必填'
            }"
          >
            <a-date-picker
              v-model="form.endTime"
              valueFormat="YYYY-MM-DD HH:mm"
              format="YYYY-MM-DD HH:mm"
              :disabled-date="disabledEndDate"
              :show-time="{ format: 'h:mm' }"
            />
          </a-form-model-item>

          <a-form-model-item
            required
            label="助力人数"
            prop="num"
            :rules="[
              {
                required: true,
                message: '助力人数必填'
              }
            ]"
          >
            <a-input-number v-model="form.num" :min="2" :max="100" />
          </a-form-model-item>

          <a-form-model-item
            required
            label="分享者优惠券"
            help="请确保优惠券库存充足"
            prop="couponId"
            :rules="[
              {
                required: true,
                message: '优惠券必填'
              }
            ]"
          >
            <a-select v-model="form.couponId" placeholder="请选择">
              <a-select-option v-for="c in couponList" :key="c.id" :value="c.id"
                >{{ c.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            required
            label="助力者优惠券"
            help="请确保优惠券库存充足，例如：预计参加活动人数100，要求3个助力，优惠券库存至少需要300张。"
            prop="coupon2Id"
            :rules="[
              {
                required: true,
                message: '优惠券必填'
              }
            ]"
          >
            <a-select v-model="form.coupon2Id" placeholder="请选择">
              <a-select-option v-for="c in couponList" :key="c.id" :value="c.id"
                >{{ c.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            required
            label="分享标题"
            style="margin-top:50px"
            prop="shareTitle"
            :rules="[
              {
                required: true,
                message: '分享标题必填'
              }
            ]"
          >
            <a-input v-model="form.shareTitle" />
          </a-form-model-item>
          <a-form-item
            label="分享封面"
            help="建议长宽比：5:4，例如：600 x 480"
            required
            prop="shareImg"
            :rules="[
              {
                required: true,
                message: '分享封面必填'
              }
            ]"
          >
            <a-upload
              :data="{ index: 0 }"
              listType="picture-card"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <img
                v-if="form.shareImg"
                :src="form.shareImg"
                style="width:80px"
              />
              <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
          </a-form-item>

          <a-form-model-item :wrapper-col="{ span: 18, offset: 6 }">
            <a-button type="primary" html-type="submit" @click="submitForm">
              保存
            </a-button>
            <a-button @click="cancel" style="margin-left: 10px;">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const moment = require("moment");

const ShareSvc = require("@/service/share");

import loadCouponMixin from "@/mixins/loadCoupon";
import couponMixin from "@/mixins/coupon";
import textMixin from "@/mixins/text";
import uploadMixin from "@/mixins/upload";

export default {
  name: "ShareEdit",

  data() {
    return {
      mode: "add", // 模式：add 批量新增，edit 单个编辑

      form: {
        title: "",
        startTime: null,
        endTime: null,
        num: 3,
        couponId: null,
        coupon2Id: null,

        shareTitle: "送你一张优惠券~",
        shareImg: "https://store.xinsailei.com/icon/share-icon.png"
      }
    };
  },

  computed: {},

  mixins: [loadCouponMixin, couponMixin, textMixin, uploadMixin],

  methods: {
    // 活动日期
    disabledStartDate(startValue) {
      const endValue = this.form.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > moment(endValue).valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return moment(startValue).valueOf() >= endValue.valueOf();
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.form.shareImg = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let {
            id,
            title,
            startTime,
            endTime,
            num,
            couponId,
            coupon2Id,

            shareTitle,
            shareImg
          } = this.form;

          if (!shareImg) {
            this.$message.info("请上传分享封面图");
            return;
          }

          try {
            const { type, name, num1, num2 } = this.couponList.filter(
              x => x.id === couponId
            )[0];
            const {
              type: coupon2Type,
              name: coupon2Name,
              num1: coupon2Num1,
              num2: coupon2Num2
            } = this.couponList.filter(x => x.id === coupon2Id)[0];

            let val = {
              title,
              startTime,
              endTime,
              num,

              couponId,
              type,
              name,
              num1,
              num2,

              coupon2Id,
              coupon2Type,
              coupon2Name,
              coupon2Num1,
              coupon2Num2,

              shareTitle,
              shareImg
            };

            if (this.mode === "edit") {
              val.id = id;
            }
            await ShareSvc.createOrUpdate(val);

            const msg = this.mode === "add" ? "创建成功" : "保存成功";
            this.$message.info(msg);
            this.$router.replace({ name: "Share" });
          } catch (err) {
            console.error(err);
            this.$message.error("创建活动失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$router.replace({ name: "Share" });
    }
  },

  async mounted() {
    let { id } = this.$route.query || {};
    this.mode = id ? "edit" : "add";

    if (id) {
      try {
        id = parseInt(id);
        let res = await ShareSvc.detail(id);
        let {
          title,
          startTime,
          endTime,
          num,
          couponId,
          coupon2Id,
          shareTitle,
          shareImg
        } = res;
        this.form = {
          id,

          title,
          startTime,
          endTime,
          num,
          couponId,
          coupon2Id,

          shareTitle,
          shareImg
        };
      } catch (err) {
        console.error(err);
        this.$message.error("加载活动失败，错误：" + err.message);
      }
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-share-edit {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;

    .ant-form {
      max-width: 600px;
    }

    .day-input-wrap {
      display: flex;
      align-items: center;

      .ant-input-affix-wrapper {
        width: 100px;
        margin: 0 5px;
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    padding: 10px;
    text-align: center;
  }
}
</style>
